import { useEffect, useState } from 'react';

export default function useDebounce(searchInput, setIsTyping, isMobile) {
  const [debouncedValue, setDebouncedValue] = useState('');

  useEffect(() => {
    setIsTyping(true);

    if (!searchInput) {
      setIsTyping(false);
      return setDebouncedValue(searchInput);
    }

    const timeoutId = setTimeout(
      () => {
        setDebouncedValue(searchInput);
        setIsTyping(false);
      },
      isMobile ? 2000 : 500
    );

    return () => clearTimeout(timeoutId);
  }, [searchInput, setIsTyping, isMobile]);

  return debouncedValue;
}
