import { Link } from 'react-router-dom';
import scrollToSection from '../../utils/scrollToSection';

export default function Logo({ className, isInDrawer = false }) {
  return (
    <Link className={className} to="/" onClick={() => scrollToSection('home')}>
      <img
        src="/logo.svg"
        alt="Feller by Schneider Electric"
        className={isInDrawer ? 'offcanvas-logo' : 'logo'}
      />
    </Link>
  );
}
