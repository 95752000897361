import { useEffect, useState } from 'react';
import { ArrowUpIcon } from '@heroicons/react/24/solid';

import scrollToSection from '../../utils/scrollToSection';

export default function ScrollToTop() {
  const [isVisible, setIsVisible] = useState(false);
  const toggleVisibility = () => {
    if (window.scrollY > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  return (
    <div
      className="position-fixed top-scroll-btn-y start-scroll-btn-x"
      style={{ zIndex: 99 }}
    >
      <button
        type="button"
        onClick={() => scrollToSection('home')}
        className={`scroll-button rounded-circle border-0 ${
          isVisible ? 'opacity-100' : 'opacity-0'
        }`}
      >
        <ArrowUpIcon style={{ height: 24 }} fill="#fff" aria-hidden="true" />
      </button>
    </div>
  );
}
