import axios from 'axios';

const createOrder = async (formData, authToken) => {
  return axios.post('/order', formData, {
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
    },
  });
};

export { createOrder };
