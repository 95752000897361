import { atom } from 'recoil';

const persistAtom = ({ setSelf, onSet }) => {
  const savedValue = sessionStorage.getItem('customCompany');
  if (savedValue != null) {
    setSelf(JSON.parse(savedValue));
  }

  onSet(newValue => {
    sessionStorage.setItem('customCompany', JSON.stringify(newValue));
  });
};

const customCompanyState = atom({
  key: 'customCompanyState',
  default: '',
  effects_UNSTABLE: [persistAtom],
});

export default customCompanyState;
