export default function SubmitButton({
  submitText,
  sendingText,
  isSending,
  isDisabled,
}) {
  return (
    <div className="text-left my-5">
      <button
        className="btn btn-lg btn-primary"
        type="button"
        disabled={isDisabled}
        data-bs-toggle="modal"
        data-bs-target="#submitModal"
      >
        {isSending ? sendingText : submitText}
      </button>
    </div>
  );
}
