import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';

export default function LogoutButton({ username, isLogout }) {
  const { logout } = useAuth0();
  const { t } = useTranslation();

  const { REACT_APP_ACCOUNT_LINK } = process.env;

  if (isLogout) {
    return (
      <div
        className={`d-flex flex-column ${username ? 'align-items-end' : ''}`}
      >
        <span className="mb-0 heading-6 text-nowrap">{username}</span>
        <span
          onClick={() =>
            logout({ logoutParams: { returnTo: window.location.origin } })
          }
          className="mb-0 fw-bolder heading-6 btn-link text-decoration-none"
          role="button"
        >
          {t('logout')}
        </span>
      </div>
    );
  }

  return (
    <div className="dropdown d-flex justify-content-start">
      <span
        data-bs-toggle="dropdown"
        className="mb-0 heading-6 text-nowrap cursor-pointer"
      >
        {username}
        <img
          src="./assets/down-arrow.svg"
          alt="close modal"
          style={{ height: 9, width: 15, marginLeft: 5, marginBottom: 2 }}
        />
      </span>
      <ul className="dropdown-menu" style={{ width: '130px' }}>
        <a
          target="blank"
          style={{ textDecoration: 'none' }}
          href={REACT_APP_ACCOUNT_LINK}
        >
          <li
            className=" cursor-pointer"
            style={{ fontSize: 15, marginLeft: 12 }}
          >
            {t('myProfile')}
          </li>
        </a>
        <li
          className=" cursor-pointer"
          style={{ fontSize: 15, marginLeft: 12, marginTop: 8 }}
          onClick={() =>
            logout({ logoutParams: { returnTo: window.location.origin } })
          }
        >
          {t('logout')}
        </li>
      </ul>
    </div>
  );
}
