import { selector } from 'recoil';

import appLanguage from './atom';

const getLanguage = selector({
  key: 'getLanguage',
  get: ({ get }) => {
    return get(appLanguage)
  }
});

export { getLanguage };
