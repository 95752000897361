import Spinner from '../spinner';

export default function FullPageSpinner() {
  return (
    <div
      style={{ height: '100vh' }}
      className="d-flex justify-content-center align-items-center"
    >
      <Spinner style={{ width: 40, height: 40 }} />
    </div>
  );
}
