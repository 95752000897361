export default function ProductImage({ url }) {
  return (
    <img
      src={url}
      alt="product header"
      className="card-img-top product-image"
      loading="lazy"
    />
  );
}
