import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';

import selectedProducts from '../../../../recoil/selected-products';
import useProducts from '../../../../actions/products';
import handleProductLanguage from '../../../../utils/handleProductLanguage';

export default function SelectedProducts() {
  const [selected] = useRecoilState(selectedProducts);
  const { t } = useTranslation();
  const { removeSelected } = useProducts();

  return (
    <div id="footer" className="container-fluid px-0  scroll-margin-top">
      <div className="container-md mx-auto ">
        <div className="border-top  border-bottom">
          <h4 style={{ paddingLeft: '20px', paddingTop: 35 }}>
            {t('order_heading')}
          </h4>

          <div
            className="my-4 d-flex flex-wrap"
            style={{ gap: '12px 28px', paddingLeft: '20px' }}
          >
            {selected.length === 0 && (
              <h6>{t('no_samples_selected_message')} </h6>
            )}
            {selected.length > 0 &&
              selected.map(product => (
                <div
                  className="position-relative d-flex flex-column align-items-start"
                  key={product?.id}
                >
                  <button
                    type="button"
                    onClick={() => removeSelected(product?.id)}
                    className="btn-close position-absolute translate-middle top-0 start-100"
                    aria-label="Close"
                  />
                  <span className="selected-product-card">
                    <img
                      className="card-header mb-2"
                      src={
                        product?.image_name
                          ? `/assets/images/${product?.image_name}`
                          : product?.image_url
                      }
                      width={'100%'}
                      alt="selected product"
                    />
                    <p className="paragraph-3">
                      {handleProductLanguage(product?.name)}{' '}
                    </p>
                  </span>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}
