import { useAuth0 } from '@auth0/auth0-react';
import Spinner from '../../../spinner';
import LoginButton from '../profile/components/LoginButton';
import Avatar from '../profile/components/Avatar';

export default function ProfileMobile() {
  const { user, isAuthenticated, isLoading } = useAuth0();
  return (
    <div className={`d-lg-none ${!isAuthenticated ? 'me-4' : ''}`}>
      {!isAuthenticated && !isLoading && <LoginButton />}
      {isLoading && <Spinner />}
      {isAuthenticated && <Avatar user={user} mobile />}
    </div>
  );
}
