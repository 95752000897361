import { useEffect, useRef } from 'react';

export default function useLoadingBar() {
  const ref = useRef(null);

  useEffect(() => {
    const cleanupRef = ref.current;
    ref.current?.continuousStart();

    if (document.readyState === 'complete') {
      ref.current?.complete();
    } else {
      window.addEventListener('load', () => ref.current?.complete(), false);
    }

    return () =>
      window.removeEventListener('load', () => cleanupRef?.complete());
  }, []);

  return ref;
}
