import { useAuth0 } from '@auth0/auth0-react';

import LoginButton from './components/LoginButton';
import LogoutButton from './components/LogoutButton';
import Avatar from './components/Avatar';
import Spinner from '../../../spinner';

export default function Profile() {
  const { user, isAuthenticated, isLoading } = useAuth0();
  return (
    <>
      {!isAuthenticated && !isLoading && <LoginButton />}

      {isLoading && <Spinner />}

      {isAuthenticated && !isLoading && (
        <div className="d-flex align-items-center gap-1">
          <Avatar user={user} />
          <LogoutButton username={user?.name} />
        </div>
      )}
    </>
  );
}
