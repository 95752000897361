import { XMarkIcon } from '@heroicons/react/24/outline';
import {
  ExclamationTriangleIcon,
  CheckCircleIcon,
} from '@heroicons/react/24/solid';

import useMessageAlert from '../../hooks/useMessageAlert';
import { ALERT_SUCCESS } from '../../constants/alert';

export default function MessageAlert() {
  const { removeAlert, text, type, position } = useMessageAlert();

  return text && type && position ? (
    <div
      className={`alert alert-header position-sticky show fade border-0 rounded-0 p-0 pb-2 pt-2 alert-${type} ${position}`}
      role="alert"
    >
      <div className="container-md">
        <div className="d-flex justify-content-between align-items-start">
          <div className="alert-text d-flex justify-content-center align-items-start">
            {type === ALERT_SUCCESS ? (
              <CheckCircleIcon
                className={`text-${type} me-3 flex-shrink-0`}
                style={{ height: 24, width: 24 }}
              />
            ) : (
              <ExclamationTriangleIcon
                className={`text-${type} me-3 flex-shrink-0`}
                style={{ height: 24, width: 24 }}
              />
            )}
            <p className="m-0">{text}</p>
          </div>
          <button
            type="button"
            onClick={() => removeAlert()}
            className="btn p-0 mt-3"
          >
            <XMarkIcon style={{ height: 24, width: 24 }} />
          </button>
        </div>
      </div>
    </div>
  ) : null;
}
