import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';

import LoginOrRegister from '../../../header/components/profile/components/LoginOrRegister';

export default function FooterAuthAlert({ alertText }) {
  return (
    <div
      className="alert alert-warning  border-0 rounded-0 d-block m-0 px-2 py-0"
      role="alert"
    >
      <div className="alert-text d-flex justify-content-center align-items-start">
        <ExclamationTriangleIcon
          className="text-warning me-3 flex-shrink-0"
          style={{ height: 24, width: 24 }}
        />
        <p className="m-0">
          {alertText}
          <span role="button" className="ms-2">
            <LoginOrRegister />
          </span>
        </p>
      </div>
    </div>
  );
}
