import { atom } from 'recoil';

const persistAtom = ({ setSelf, onSet }) => {
  const savedValue = sessionStorage.getItem('selectedProducts');
  if (savedValue != null) {
    setSelf(JSON.parse(savedValue));
  }

  onSet(newValue => {
    sessionStorage.setItem('selectedProducts', JSON.stringify(newValue));
  });
};

const selectedProducts = atom({
  key: 'selectedProducts',
  default: [],
  effects_UNSTABLE: [persistAtom],
});

export default selectedProducts;
