export default function Avatar({ user, mobile }) {
  const { REACT_APP_ACCOUNT_LINK } = process.env;

  if (mobile) {
    return (
      <a
        target="blank"
        style={{ marginRight: 10 }}
        className="cursor-pointer"
        href={REACT_APP_ACCOUNT_LINK}
      >
        <img
          style={{
            width: 40,
            borderRadius: 50,
          }}
          src={user?.picture}
          alt={user?.name}
        />
      </a>
    );
  } else {
    return (
      <img
        style={{ width: 40, borderRadius: 50, marginRight: 10 }}
        src={user?.picture}
        alt={user?.name}
      />
    );
  }
}
