export default function MenuItem({ name, url }) {
  return (
    <li className="nav-link" style={{ marginBottom: 15 }}>
      <a
        className="btn-link paragraph-2"
        target="_blank"
        rel="noopener noreferrer"
        href={url}
      >
        {name}
      </a>
    </li>
  );
}
