import { useRecoilValue } from 'recoil';
import { ShoppingCartIcon } from '@heroicons/react/24/outline';

import selectedProducts from '../../../../recoil/selected-products';
import scrollToSection from '../../../../utils/scrollToSection';

export default function Cart({ label }) {
  const selected = useRecoilValue(selectedProducts);
  return (
    <button
      onClick={() => scrollToSection()}
      className="btn btn-lg btn-light bg-white header-btn border-0 position-relative d-flex flex-column p-0"
    >
      <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-secondary">
        {selected.length}
      </span>

      <span className="d-flex flex-column align-items-center gap-1">
        <ShoppingCartIcon strokeWidth="2" className="header-btn-icon" />
        <span className="header-btn-label">{label}</span>
      </span>
    </button>
  );
}
