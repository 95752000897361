import { useRecoilState } from 'recoil';
import i18n from '../../../../i18n';

import appLanguage from '../../../../recoil/language';
import languages from '../../../../constants/languages';

export default function LanguageSelectMobile() {
  const [language, setLanguage] = useRecoilState(appLanguage);
  const langArray = Object.values(languages);

  const handleLanguageChange = lang => {
    setLanguage(lang);
    i18n.changeLanguage(lang);
  };
  return (
    <div
      className="d-flex justify-content-start align-items-center"
      style={{ marginLeft: '10px' }}
    >
      {langArray.map((lang, index) => (
        <button
          key={index}
          className={`btn btn-link text-decoration-none heading-6`}
          style={{
            marginRight: '-10px',
          }}
          onClick={() => handleLanguageChange(lang)}
        >
          <span
            style={{
              textDecoration: language === lang ? 'underline' : 'none',
              textUnderlineOffset: '6px',
            }}
          >
            {lang?.toUpperCase()}
          </span>
        </button>
      ))}
    </div>
  );
}
