import { useAuth0 } from '@auth0/auth0-react';
import { Bars3Icon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';

import useMessageAlert from '../../hooks/useMessageAlert';
import Profile from './components/profile';
import Cart from './components/cart';
import LogoutButton from './components/profile/components/LogoutButton';
import LoginOrRegister from './components/profile/components/LoginOrRegister';
import LanguageSelect from './components/language-select';
import LanguageSelectMobile from './components/language-select/mobile';
import SearchBox from './components/search-box';
import Avatar from './components/profile/components/Avatar';
import Logo from '../logo';
import ProfileMobile from './components/profile-mobile';

export default function Header() {
  const { isAuthenticated, user } = useAuth0();
  const { t } = useTranslation();
  const { removeAlert } = useMessageAlert();

  return (
    <header
      className={
        'border-bottom bg-white header ' +
        (isAuthenticated ? 'position-sticky top-0' : '')
      }
    >
      <nav className="navbar nav-container d-flex justify-content-between align-items-center">
        <Logo className="navbar-brand m-0" />

        <div
          className="offcanvas offcanvas-end offcanvas-menu mr-5"
          tabIndex={-1}
          id="offcanvasMenu"
        >
          <div className="offcanvas-header">
            <Logo className="navbar-brand m-0 p-0" isInDrawer={true} />

            <button
              type="button"
              className="btn btn-lg btn-light bg-white border-0 p-0"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              <img
                src="./assets/menuCloseIcon.svg"
                alt="close menu"
                style={{ height: 30, width: 30 }}
              />
            </button>
          </div>

          <div className="offcanvas-body d-flex d-lg-none flex-column justify-content-between pb-4 px-4 w-full">
            <ul className="p-0 m-0">
              <li className="d-flex justify-content-end align-items-center gap-3 heading-5 py-4 offcanvas-border">
                {isAuthenticated ? (
                  <p className="m-0">{user.name}</p>
                ) : (
                  <LoginOrRegister />
                )}
                {isAuthenticated ? <Avatar user={user} mobile /> : null}
              </li>
              <li className="d-flex justify-content-end py-4">
                <SearchBox label={t('search_label')} isInDrawer={true} />
              </li>
            </ul>

            <div className="mobile-footer">
              <div className="mobile-footer-select">
                <LanguageSelectMobile />
              </div>
              <div>{isAuthenticated ? <LogoutButton isLogout /> : null}</div>
            </div>
          </div>
        </div>

        <div
          className={`d-flex justify-content-end align-items-center position-relative ${
            isAuthenticated ? '' : ''
          }`}
        >
          <div className="d-none d-lg-block header-group">
            <SearchBox label={t('search_label')} />
          </div>

          <div className="d-none d-lg-block">
            <LanguageSelect />
          </div>

          <div
            className={`d-flex flex-row ${isAuthenticated ? '' : 'd-none'}`}
            style={{ marginRight: '20px' }}
          >
            {isAuthenticated && <Cart label={t('cart_label')} />}
          </div>

          <ProfileMobile />

          <div className={`d-none d-lg-flex ${!isAuthenticated ? 'me-4' : ''}`}>
            <Profile />
          </div>

          <button
            className="btn btn-lg btn-light bg-white header-btn border-0 d-flex flex-column align-items-center gap-1 d-lg-none p-0"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasMenu"
            onClick={() => removeAlert()}
          >
            <Bars3Icon strokeWidth="2" className="header-btn-icon" />
            <span className="header-btn-label">{t('menu_label')}</span>
          </button>
        </div>
      </nav>
    </header>
  );
}
