import SocialMedia from '../social-media';

export default function SocialLinks() {
  return (
    <div className="d-flex align-items-center justify-content-evenly gap-4 mt-5 mt-md-0">
      <SocialMedia
        url="https://www.linkedin.com/company/feller-ag"
        icon="./assets/linkedinIcon.svg"
        name="linkedin"
      />
      <SocialMedia
        url="https://www.youtube.com/channel/UCcrBkHSuMiQEfUKRunfvUAA"
        icon="./assets/youtubeIcon.svg"
        name="youtube"
      />
      <SocialMedia
        url="https://www.instagram.com/feller.ag"
        icon="./assets/instagramIcon.svg"
        name="instagram"
      />
      <SocialMedia
        url="https://www.facebook.com/Feller-AG-105713954751058/"
        icon="./assets/facebookIcon.svg"
        name="facebook"
      />
      <SocialMedia
        url="https://twitter.com/fellerag"
        icon="./assets/twitterIcon.svg"
        name="twitter"
        padding={4}
      />
    </div>
  );
}
