import { useQuery } from '@tanstack/react-query';
import { getServicesStatus } from '../../actions/status';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/solid';

const StatusIndicator = ({ label, status }) => (
  <div className="my-1">
    <span className="me-3">{label}</span>
    {status === 'Healthy' ? (
      <CheckCircleIcon style={{ height: 24, width: 24, color: 'green' }} />
    ) : (
      <XCircleIcon style={{ height: 24, width: 24, color: 'red' }} />
    )}
  </div>
);

export default function ServicesStatus() {
  const { data, error, isLoading } = useQuery(['status'], getServicesStatus);

  const serviceStatuses = data?.data;

  let content;
  if (isLoading) {
    content = <p>Loading...</p>;
  } else if (error) {
    content = (
      <>
        <p className="heading-3 mb-2">Services Health Status</p>
        <StatusIndicator
          label="Overall status"
          status={error?.response?.data?.overallStatus || 'Unhealthy'}
        />
        <StatusIndicator label="API" status={'Unhealthy'} />
        <p>
          Error: {error?.message} (Order samples API fails, can't retrieve
          service statuses)
        </p>
      </>
    );
  } else {
    content = (
      <>
        <p className="heading-3 mb-2">Services Health Status </p>
        <StatusIndicator
          label="Overall status"
          status={serviceStatuses.overallStatus}
        />
        <br />
        <StatusIndicator label="API" status={serviceStatuses.orderSamplesAPI} />
        <StatusIndicator label="Database" status={serviceStatuses.database} />
        <StatusIndicator
          label="myFeller API"
          status={serviceStatuses.fellerAPI}
        />
        <StatusIndicator
          label="Mailing API"
          status={serviceStatuses.mandrillApi}
        />
        <StatusIndicator
          label="Authentication API"
          status={serviceStatuses.auth0Api}
        />
      </>
    );
  }

  return <div className="container-md my-5">{content}</div>;
}
