const yup = require('yup');

const userCompanySchema = yup.object().shape({
  company_details: yup
    .object()
    .shape({
      headquarter: yup
        .object()
        .shape({
          name: yup.string().required(),
          address: yup
            .object()
            .shape({
              street: yup.string().required(),
              city: yup.string().required(),
              zip: yup.string().required(),
            })
            .required(),
        })
        .required(),
    })
    .required(),
});

export default userCompanySchema;
