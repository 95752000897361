/* eslint-disable react-hooks/exhaustive-deps */
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useUserData from './useUserData';

export default function useHandleForm(cartProducts, currentLanguage, onSubmit) {
  const { userData, isCheckboxDisabled } = useUserData();
  const methods = useForm({
    defaultValues: {
      orderedProducts: cartProducts,
      street: userData.street,
      zip: userData.zip,
      city: userData.city,
      country: 'CH',
      contactPerson: '',
      differentAddressCheck: false,
      reasonForOrder: '',
      userOrderReason: '',
      dataAndPrivacyCheck: false,
      avbCheck: false,
    },
    mode: 'all',
  });
  const { t } = useTranslation();

  const isDifferentAddress = methods.watch('differentAddressCheck');
  const isOtherChecked =
    methods.watch('reasonForOrder').de ===
    t('other_reason_order_label', { lng: 'de' });
  const isReasonNewProject =
    methods.watch('reasonForOrder').de ===
    t('first_reason_order_label', { lng: 'de' });

  const isReasonRenovationProject =
    methods.watch('reasonForOrder').de ===
    t('second_reason_order_label', { lng: 'de' });

  const shouldUserInputReasonText =
    isReasonNewProject || isReasonRenovationProject;

  useEffect(() => {
    if (isDifferentAddress || isCheckboxDisabled) {
      methods.setValue('street', '', { shouldValidate: true });
      methods.setValue('city', '', { shouldValidate: true });
      methods.setValue('zip', '', { shouldValidate: true });
      methods.setValue('country', 'CH');
      methods.setValue('differentAddressCheck', true);
    } else {
      methods.setValue('street', userData.street);
      methods.setValue('city', userData.city);
      methods.setValue('zip', userData.zip);
      methods.setValue('country', 'CH');
      methods.unregister('contactPerson');
    }
  }, [isDifferentAddress, isCheckboxDisabled]);

  useEffect(() => {
    methods.setValue('orderedProducts', cartProducts);
  }, [cartProducts]);

  useEffect(() => {
    if (shouldUserInputReasonText || isOtherChecked) {
      methods.setValue('userOrderReason', '', { shouldValidate: true });
    } else {
      methods.unregister('userOrderReason');
    }
  }, [
    isReasonNewProject,
    isReasonRenovationProject,
    isOtherChecked,
    methods.setValue,
    methods.unregister,
  ]);

  useEffect(() => {
    if (isCheckboxDisabled && isCheckboxDisabled !== isDifferentAddress) {
      methods.setValue('differentAddressCheck', true);
    }
  }, [onSubmit]);

  return {
    methods,
    isDifferentAddress,
    shouldUserInputReasonText,
    isOtherChecked,
    isCheckboxDisabled,
    userData,
  };
}
