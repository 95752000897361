import { useAuth0 } from '@auth0/auth0-react';
import { UserIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';

export default function LoginButton() {
  const { loginWithRedirect } = useAuth0();
  const { t } = useTranslation();

  return (
    <button
      className="btn btn-lg btn-light bg-white header-btn border-0 d-flex flex-column align-items-center gap-1 p-0"
      onClick={() => loginWithRedirect()}
    >
      <UserIcon strokeWidth="2" className="header-btn-icon" />
      <span className="header-btn-label">{t('login')}</span>
    </button>
  );
}
