export const DATA_PROTECTION_URL = {
  de: 'https://www.feller.ch/de/privacy_notice_professional_users',
  fr: 'https://www.feller.ch/fr/privacy_notice_professional_users',
  it: 'https://www.feller.ch/it/privacy_notice_professional_users',
};

export const CONDITIONS_OF_PURCHASE_URL = {
  de: 'https://www.feller.ch/de/aeb',
  fr: 'https://www.feller.ch/fr/cga',
  it: 'https://www.feller.ch/it/cga',
};

export const COOKIE_SETTINGS_URL = {
  de: 'https://www.feller.ch/de',
  fr: 'https://www.feller.ch/fr',
  it: 'https://www.feller.ch/it',
};

export const DSGVO_URL = {
  de: 'https://www.feller.ch/de/dsgvo-vertreter',
  fr: 'https://www.feller.ch/fr/rgpd-representant',
  it: 'https://www.feller.ch/it/rappresentante-gdpr',
};

export const TERMS_URL = {
  de: 'https://www.feller.ch/de/nutzungsbedingungen',
  fr: 'https://www.feller.ch/fr/conditions-dutilisation',
  it: 'https://www.feller.ch/it/condizioni-di-utilizzo',
};

export const IMPRINT_URL = {
  de: 'https://www.feller.ch/de/impressum',
  fr: 'https://www.feller.ch/fr/mentions-legales',
  it: 'https://www.feller.ch/it/dati-editoriali',
};

export const GENERAL_SALE_CONDITIONS_URL = {
  de: 'https://www.feller.ch/de/avb',
  fr: 'https://www.feller.ch/fr/cgv',
  it: 'https://www.feller.ch/it/cgv',
};

export const CODE_OF_CONDUCT_URL = {
  de: 'https://www.feller.ch/de/verhaltenskodex',
  fr: 'https://www.feller.ch/fr/code-conduite',
  it: 'https://www.feller.ch/it/codice-di-comportamento',
};

export const ABOUT_US_URL = {
  de: 'https://www.feller.ch/de/ueber-uns',
  fr: 'https://www.feller.ch/fr/qui-nous-sommes',
  it: 'https://www.feller.ch/it/chi-siamo',
};

export const LOCATIONS_URL = {
  de: 'https://www.feller.ch/de/standorte',
  fr: 'https://www.feller.ch/fr/sites',
  it: 'https://www.feller.ch/it/sedi',
};

export const OUR_STORY_URL = {
  de: 'https://www.feller.ch/de/geschichte',
  fr: 'https://www.feller.ch/fr/histoire',
  it: 'https://www.feller.ch/it/storia',
};

export const APPRENTICESHIPS_URL = {
  de: 'https://www.feller.ch/de/lehrberufe',
  fr: 'https://www.feller.ch/fr/metiers-d-apprentissage',
  it: 'https://www.feller.ch/it/apprendistati',
};

export const INNOVATION_HUB_HORGEN_URL = {
  de: 'https://www.feller.ch/de/innovation-hub-horgen',
  fr: 'https://www.feller.ch/fr/innovation-hub-horgen',
  it: 'https://www.feller.ch/it/innovation-hub-horgen',
};

export const SUSTAINABILITY_URL = {
  de: 'https://www.feller.ch/de/nachhaltigkeit',
  fr: 'https://www.feller.ch/fr/durabilite',
  it: 'https://www.feller.ch/it/sostenibilita',
};

export const CAREERS_URL = {
  de: 'https://www.feller.ch/de/arbeiten-bei-feller',
  fr: 'https://www.feller.ch/fr/travailler-chez-feller',
  it: 'https://www.feller.ch/it/lavorare-in-feller',
};

export const PARTNERS_URL = {
  de: 'https://www.feller.ch/de/partner-und-verbaende',
  fr: 'https://www.feller.ch/fr/partenaires-et-associations',
  it: 'https://www.feller.ch/it/partner-e-associazioni',
};

export const ONLINE_CATALOG_URL = {
  de: 'https://online-katalog.feller.ch/kat.php?&sc_lang=de',
  fr: 'https://online-katalog.feller.ch/kat.php?&sc_lang=fr',
  it: 'https://online-katalog.feller.ch/kat.php?&sc_lang=it',
};

export const WISER_BY_FELLER_URL = {
  de: 'https://wiser.feller.ch/de/professionals/',
  fr: 'https://wiser.feller.ch/fr/professionals/',
  it: 'https://wiser.feller.ch/it/professionals/',
};

export const BILDUNG_URL = {
  de: 'https://www.feller.ch/de/academy/bildungsangebot',
  fr: 'https://www.feller.ch/fr/academy/offre-de-formation',
  it: 'https://www.feller.ch/it/academy/offerta-formativa',
};

export const SCHALTER_URL = {
  de: 'https://www.feller.ch/de/sortiment/schalter-und-taster',
  fr: 'https://www.feller.ch/fr/gamme/interrupteurs-et-poussoirs',
  it: 'https://www.feller.ch/it/assortimento/interruttori-e-pulsante',
};

export const DUE_COLORE_URL = {
  de: 'https://www.feller.ch/de/designlinien/ediziodue-colore',
  fr: 'https://www.feller.ch/fr/lignes-de-design/ediziodue-colore',
  it: 'https://www.feller.ch/it/linee-di-design/ediziodue-colore',
};

export const KNX_URL = {
  de: 'https://www.feller.ch/de/connected-buildings/knx',
  fr: 'https://www.feller.ch/fr/connected-buildings/knx',
  it: 'https://www.feller.ch/it/connected-buildings/knx',
};

export const DOWNLOAD_URL = {
  de: 'https://online-katalog.feller.ch/download/index.php?sc_lang=de',
  fr: 'https://online-katalog.feller.ch/download/index.php?sc_lang=fr',
  it: 'https://online-katalog.feller.ch/download/index.php?sc_lang=it',
};

export const TRAINING_URL = {
  de: 'https://online-katalog.feller.ch/ausbildung/index.php?sc_lang=de',
  fr: 'https://online-katalog.feller.ch/ausbildung/index.php?sc_lang=fr',
  it: 'https://online-katalog.feller.ch/ausbildung/index.php?sc_lang=it',
};

export const CONTACT_URL = {
  de: 'https://www.feller.ch/de/kontakt',
  fr: 'https://www.feller.ch/fr/contact',
  it: 'https://www.feller.ch/it/contatti',
};

export const HELP_CENTER_URL = {
  de: 'https://www.feller.ch/de/customer-service-center',
  fr: 'https://www.feller.ch/fr/customer-service-center',
  it: 'https://www.feller.ch/it/customer-service-center',
};

export const NEWS_URL = {
  de: 'https://www.feller.ch/de/news',
  fr: 'https://www.feller.ch/fr/nouvelles',
  it: 'https://www.feller.ch/it/notizie',
};

export const PRESS_AND_MEDIA_URL = {
  de: 'https://www.feller.ch/de/presse-und-medien',
  fr: 'https://www.feller.ch/fr/presse-et-medias',
  it: 'https://www.feller.ch/it/stampa-e-media',
};

export const PRODUKTION_URL = {
  de: 'https://www.feller.ch/de/produktion',
  fr: 'https://www.feller.ch/fr/production',
  it: 'https://www.feller.ch/it/produzione',
};

export const ARBEITEN_BEI_FELLER_URL = {
  de: 'https://www.feller.ch/de/arbeiten-bei-feller',
  fr: 'https://www.feller.ch/fr/travailler-chez-feller',
  it: 'https://www.feller.ch/it/lavorare-in-feller',
};

export const VACANCIES_BEI_FELLER_URL = {
  de: 'https://www.feller.ch/de/offene-stellen',
  fr: 'https://www.feller.ch/fr/offres-d-emploi',
  it: 'https://www.feller.ch/it/posti-vacanti',
};

export const WORKPLACE_BEI_FELLER_URL = {
  de: 'https://www.feller.ch/de/arbeitsort',
  fr: 'https://www.feller.ch/fr/lieu-de-travail',
  it: 'https://www.feller.ch/it/sede-di-lavoro',
};

export const FELLER_APPS_URL = {
  de: 'https://www.feller.ch/de/feller-apps',
  fr: 'https://www.feller.ch/fr/feller-apps',
  it: 'https://www.feller.ch/it/feller-apps',
};

export const TUTORIALS_URL = {
  de: 'https://www.youtube.com/channel/UCcrBkHSuMiQEfUKRunfvUAA',
  fr: 'https://www.youtube.com/channel/UCcrBkHSuMiQEfUKRunfvUAA',
  it: 'https://www.youtube.com/channel/UCcrBkHSuMiQEfUKRunfvUAA',
};

export const SALES_AID_URL = {
  de:
    'https://online-katalog.feller.ch/download/index.php?menueidLev1=331&sc_lang=de',
  fr:
    'https://online-katalog.feller.ch/download/index.php?menueidLev1=331&sc_lang=fr',
  it:
    'https://online-katalog.feller.ch/download/index.php?menueidLev1=331&sc_lang=it',
};

export const DISCONTINUATIONS_URL = {
  de:
    'https://online-katalog.feller.ch/download/index.php?menueidLev1=937&sc_lang=de',
  fr:
    'https://online-katalog.feller.ch/download/index.php?menueidLev1=937&sc_lang=fr',
  it:
    'https://online-katalog.feller.ch/download/index.php?menueidLev1=937&sc_lang=it',
};

export const THEMEN_URL = {
  de: 'https://www.feller.ch/de/themenwelten/connected-home',
  fr: 'https://www.feller.ch/fr/themenwelten/connected-home',
  it: 'https://www.feller.ch/it/themenwelten/connected-home',
};

export const REFERENCE_URL = {
  de: 'https://www.feller.ch/de/themenwelten/referenzen/umbauprojekt-la-casa',
  fr:
    'https://www.feller.ch/fr/univers-thematique/references/projet-de-transformation-la-casa',
  it:
    'https://www.feller.ch/it/area-tematica/referenze/progetto-di-ristrutturazione-la-casa',
};

export const EVENTS_URL = {
  de: 'https://www.feller.ch/de/events',
  fr: 'https://www.feller.ch/fr/evenements',
  it: 'https://www.feller.ch/it/eventi',
};

export const NEWSLETTER_URL = {
  de: 'https://www.feller.ch/de/newsletter',
  fr: 'https://www.feller.ch/fr/newsletter',
  it: 'https://www.feller.ch/it/newsletter',
};

export const FELLER_AG_NAME = 'Feller AG';
export const FELLER_AG_STREET = 'Bergstrasse 70';
export const FELLER_AG_CITY = '8810 Horgen';
export const FELLER_AG_PHONE = '044 728 77 77';

export const FELLER_SA_NAME = 'Feller SA';
export const FELLER_SA_STREET = 'Chemin de Mongevon 25';
export const FELLER_SA_CITY = '1023 Crissier';
