import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';

export default function LoginOrRegister() {
  const { loginWithRedirect } = useAuth0();
  const { t } = useTranslation();

  return (
    <span
      className="fw-bolder"
      onClick={() => loginWithRedirect()}
      role="button"
    >
      {t('loginRegister')}
    </span>
  );
}
