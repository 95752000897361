import { selector, selectorFamily } from 'recoil';

import products from './atom';
import searchQuery from '../search/atom';
import labelFilter from '../label-filter';
import handleProductSearch from '../../utils/handleProductSearch';
import appLanguage from '../language';
import toStandardizedString from '../../utils/toStandardizedString';
import i18n from 'i18next';

const getProductById = selectorFamily({
  key: 'getProductById',
  get: productId => ({ get }) => {
    return get(products)
      .flatMap(categories => categories.products)
      .flatMap(product => product.items)
      .find(product => product.id === productId);
  },
});

const getProductByCategory = selectorFamily({
  key: 'getProductByCategory',
  get: categoryName => ({ get }) => {
    let category = get(products).find(
      category => category?.name === categoryName
    );

    if (!get(searchQuery)) return category;

    if (get(labelFilter) && category.name === i18n.t('display_image_label')) {
      const itemToMove = category.products.find(
        product =>
          product.items.length > 0 &&
          product.items[0].name.de ===
            'Druckschalter, Unbeleuchtet mit Beschriftungsmuster'
      );

      if (itemToMove) {
        const updatedProducts = [
          ...category.products.filter(product => product !== itemToMove),
        ];

        category = { ...category, products: updatedProducts };
      }
    }

    const query = get(searchQuery);
    const language = get(appLanguage);
    return handleProductSearch(category, toStandardizedString(query), language);
  },
});

const getProductCategories = selector({
  key: 'getProductCategories',
  get: ({ get }) => {
    return get(products).reduce((acc, curr) => {
      if (curr.products.length > 0) {
        acc.push(curr.name);
      }

      return acc;
    }, []);
  },
});

export { getProductById, getProductByCategory, getProductCategories };
