import toStandardizedString from './toStandardizedString';

const handleProductSearch = (category, query, language) => {
  const results = { products: [] };

  if (!category?.products) return;

  for (const product of category.products) {
    const items = product.items;
    for (const item of items) {
      if (
        toStandardizedString(item.feller_number) === query ||
        toStandardizedString(item.ean) === query
      ) {
        const filteredItems = items.filter(product => product.id !== item.id);
        results.products.push({ items: [item, ...filteredItems] });

        return results;
      }

      if (
        toStandardizedString(item.feller_number).includes(query) ||
        toStandardizedString(item.ean).includes(query)
      ) {
        const filteredItems = items.filter(product =>
          product.ean.includes(item.ean)
            ? !product.ean.includes(item.ean)
            : !product.feller_number.includes(item.feller_number)
        );

        results.products.push({ items: [item, ...filteredItems] });

        break;
      }

      if (toStandardizedString(item.name[language]).includes(query)) {
        results.products.push({ items });
        break;
      }
    }
  }

  return results.products.length === 0 ? null : results;
};

export default handleProductSearch;
