import { ShoppingCartIcon as Outline } from '@heroicons/react/24/outline';
import { ShoppingCartIcon as Solid } from '@heroicons/react/24/solid';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';

import { WHITE_COLOR } from '../../../../../../../../constants/products';
import useProducts from '../../../../../../../../actions/products';
import { useTranslation } from 'react-i18next';

const TooltipProvider = TooltipPrimitive.Provider;

const Tooltip = TooltipPrimitive.Root;

const TooltipTrigger = TooltipPrimitive.Trigger;

const TooltipContent = ({ className, sideOffset = 4, ...props }) => (
  <TooltipPrimitive.Content
    sideOffset={sideOffset}
    className="tooltip-color"
    {...props}
  />
);

export default function ColorButton({
  id,
  color,
  handleColorChange,
  isCurrent = false,
  isMobile,
}) {
  const { t } = useTranslation();
  const { isChecked } = useProducts(id);

  if (isMobile) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
          fontSize: isMobile && '14px',
          marginRight: 5,
          width: 'max-content',
        }}
        className="cursor-pointer"
        onClick={() => handleColorChange(id)}
      >
        <button
          className={`${color.replaceAll(
            ' ',
            '-'
          )} p-2 text-primary mx-1 me-2 color-btn ${
            isCurrent ? 'color-btn-border-current' : ''
          }`}
          aria-label={color}
          disabled={isCurrent}
        >
          {isChecked(id) &&
            (color === WHITE_COLOR ? (
              <Outline className="position-relative translate-middle color-btn-selected" />
            ) : (
              <Solid
                className={`${color?.replaceAll(' ', '-') +
                  'Active'} position-relative translate-middle color-btn-selected`}
                stroke="#171717"
                strokeWidth={0.8}
                fill="#fff"
              />
            ))}
        </button>
        <div>{t(color)}</div>
      </div>
    );
  }

  return (
    <TooltipProvider delayDuration={100}>
      <Tooltip>
        <TooltipTrigger asChild>
          <button
            onClick={() => handleColorChange(id)}
            className={`${color.replaceAll(
              ' ',
              '-'
            )} p-2 text-primary mx-1 color-btn ${
              isCurrent ? 'color-btn-border-current' : ''
            }`}
            aria-label={color}
            disabled={isCurrent}
          >
            {isChecked(id) &&
              (color === WHITE_COLOR ? (
                <Outline className="position-relative translate-middle color-btn-selected" />
              ) : (
                <Solid
                  className={`${color?.replaceAll(' ', '-') +
                    'Active'} position-relative translate-middle color-btn-selected`}
                  stroke="#171717"
                  strokeWidth={0.8}
                  fill="#fff"
                />
              ))}
          </button>
        </TooltipTrigger>
        <TooltipContent side="bottom">{t(color)}</TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}
