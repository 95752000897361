import { createContext, useRef, useState } from 'react';

import { ALERT_TIME } from '../constants/alert';

const initialState = {
  text: '',
  type: '',
  position: '',
};

const AlertContext = createContext({
  ...initialState,
  setAlert: () => {},
  removeAlert: () => {},
});

export const AlertProvider = ({ children }) => {
  const [text, setText] = useState('');
  const [type, setType] = useState('');
  const [position, setPosition] = useState('');
  const timeoutId = useRef(null);

  const resetAlert = () => {
    setText('');
    setType('');
    setPosition('');
  };

  const setAlert = (alertText, alertType, alertPosition) => {
    if (text || type || position) return;

    setText(alertText);
    setType(alertType);
    setPosition(alertPosition);

    timeoutId.current = setTimeout(() => resetAlert(), ALERT_TIME);
  };

  const removeAlert = () => {
    resetAlert();
    clearTimeout(timeoutId?.current);
  };

  return (
    <AlertContext.Provider
      value={{
        text,
        type,
        setAlert,
        removeAlert,
        position,
      }}
    >
      {children}
    </AlertContext.Provider>
  );
};

export default AlertContext;
