export const WHITE_COLOR = 'weiss';
export const MIN_NUM_OF_COLORS = 1;
export const MIN_PRODUCT_VARIANT_LENGTH = 1;

export const MAX_SELECTED_PRODUCTS_LENGTH = 5;
export const GENERIC_PRODUCT_ID = '41c99a52-c5f8-462f-a364-e4ca61846cd7';

const appBaseUrl = process.env.REACT_APP_BASE_URL;
export const GENERIC_PRODUCT = {
  id: GENERIC_PRODUCT_ID,
  feller_number: '000000000',
  e_nr: '000000000',
  ean: '0000000000000',
  image_url: `${appBaseUrl}generic.png`,
  name: {
    de: `Gesuchten Artikel konnte nicht gefunden werden, bitte kontaktieren Sie mich.`,
    fr: `L'article recherché n'a pas pu être trouvé, veuillez me contacter.`,
    it: `L'articolo ricercato non è stato trovato, contattatemi.`,
  },
};
