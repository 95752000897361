import axios from 'axios';

const getServicesStatus = async () => {
  return axios.get('/status', {
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export { getServicesStatus };
