import { CheckIcon } from '@heroicons/react/20/solid';
import { useRecoilState } from 'recoil';
import i18n from '../../../../i18n';

import appLanguage from '../../../../recoil/language';
import languages from '../../../../constants/languages';

export default function LanguageSelect() {
  const [language, setLanguage] = useRecoilState(appLanguage);
  const langArray = Object.values(languages);

  const handleLanguageChange = event => {
    setLanguage(event.target.value);
    i18n.changeLanguage(event.target.value);
  };

  return (
    <div className="dropdown d-flex justify-content-start">
      <button
        className="btn border-0 dropdown-toggle  py-0 btn-link text-decoration-none heading-6"
        type="button"
        data-bs-toggle="dropdown"
      >
        <span>{language?.toUpperCase()}</span>
      </button>
      <ul className="dropdown-menu text-end">
        {langArray.map(lang => (
          <li className="px-1" key={lang}>
            <button
              className={`dropdown-item ps-1 pe-2 rounded ${
                language === lang
                  ? 'active d-flex align-items-center gap-1'
                  : ''
              }`}
              value={lang}
              onClick={handleLanguageChange}
            >
              {language === lang && <CheckIcon height={16} />}
              {lang?.toUpperCase()}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}
