import useProducts from '../../../../../../../../actions/products';

export default function ControlCartButton({
  id,
  isLoading = false,
  addItem,
  removeItem,
}) {
  const { isChecked, setSelectedProducts } = useProducts(id);

  return (
    <>
      <label
        role="button"
        className={`card-link btn btn-primary btn-sm d-inline-flex justify-content-center align-items-start gap-1 ${
          isChecked(id) ? 'active' : ''
        }`}
      >
        <input
          name="select-button"
          className="form-check-input position-absolute btn opacity-0"
          value={id}
          onChange={!isChecked(id) ? setSelectedProducts : () => undefined}
          checked={isChecked(id)}
          type="checkbox"
          disabled={isLoading}
        />
        <small>{addItem}</small>
      </label>
    </>
  );
}
