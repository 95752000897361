import { useEffect, useState } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { InformationCircleIcon } from '@heroicons/react/24/outline';

import {
  CITY_MAX_LENGTH,
  STREET_MAX_LENGTH,
  ZIP_MAX_LENGTH,
  ZIP_MIN_LENGTH,
} from '../../../../../constants/form';

export default function DifferentAddress() {
  const [contactPerson, setContactPerson] = useState('');
  const {
    control,
    setValue,
    unregister,
    formState: { errors },
  } = useFormContext();
  const { t } = useTranslation();

  useEffect(() => {
    if (contactPerson) {
      setValue('contactPerson', contactPerson);
    } else {
      unregister('contactPerson');
    }
  }, [contactPerson, setValue, unregister]);

  return (
    <>
      <div className="row g-2">
        <div className="form-floating col-12 col-md-9 col-lg-3">
          <Controller
            name="street"
            control={control}
            rules={{
              required: {
                value: true,
                message: t('error_empty_field'),
              },
              maxLength: {
                value: STREET_MAX_LENGTH,
                message: t('error_max_length'),
              },
            }}
            render={({ field }) => (
              <>
                <input
                  type="text"
                  className={`${
                    errors.street ? 'border-danger' : ''
                  } form-control`}
                  id="street"
                  {...field}
                />
                <label
                  htmlFor="street"
                  className={`${errors.street ? 'text-danger' : ''}`}
                >
                  {t('house_optional1')}
                </label>
              </>
            )}
          />
        </div>
        <div className="form-floating col-12 col-md-3 col-lg-2">
          <Controller
            name="zip"
            control={control}
            rules={{
              required: {
                value: true,
                message: t('error_empty_field'),
              },
              min: {
                value: ZIP_MIN_LENGTH,
                message: t('error_min_length'),
              },
              max: {
                value: ZIP_MAX_LENGTH,
                message: t('error_max_number'),
              },
            }}
            render={({ field }) => (
              <>
                <input
                  type="number"
                  className={`${
                    errors.zip ? 'border-danger' : ''
                  } form-control form-control-border`}
                  id="city"
                  {...field}
                />
                <label
                  htmlFor="zip"
                  className={`${errors.zip ? 'text-danger' : ''}`}
                >
                  {t('input_zip_label')}
                </label>
              </>
            )}
          />
        </div>
        <div className="form-floating col-12 col-md-12 col-lg-3">
          <Controller
            name="city"
            control={control}
            rules={{
              required: {
                value: true,
                message: t('error_empty_field'),
              },
              maxLength: {
                value: CITY_MAX_LENGTH,
                message: t('error_max_length'),
              },
            }}
            render={({ field }) => (
              <>
                <input
                  type="text"
                  className={`${
                    errors.city ? 'border-danger' : ''
                  } form-control`}
                  id="city"
                  {...field}
                />
                <label
                  htmlFor="city"
                  className={`${errors.city ? 'text-danger' : ''}`}
                >
                  {t('input_city_label')}
                </label>
              </>
            )}
          />
        </div>

        <div className="form-floating col-12 col-lg-4">
          <Controller
            name="country"
            control={control}
            render={({ field }) => (
              <>
                <input
                  {...field}
                  className={`${
                    errors.country ? 'border-danger' : ''
                  } form-control`}
                  id="country"
                  value="CH"
                  readOnly
                />

                <label
                  htmlFor="country"
                  className={`${errors.country ? 'text-danger' : ''}`}
                >
                  {t('select_country_label')}
                </label>
              </>
            )}
          />
        </div>

        {errors && (
          <p className="text-danger mb-0">
            {errors.street?.message ||
              errors.zip?.message ||
              errors.city?.message ||
              errors.country?.message}
          </p>
        )}
      </div>

      <div className="row g-3 mt-4">
        <p className="m-0">{t('contact_person_label')}</p>

        <div className="form-floating col-12 col-md-6 mt-0">
          <input
            className="form-control"
            type="text"
            onChange={e => setContactPerson(e.target.value.trim())}
            id="contactPerson"
          />
          <label className="ms-1" htmlFor="contactPerson">
            {t('input_contact_person_label')}
          </label>
        </div>

        <div className="d-flex items-start col-12 col-md-6 mt-2 mt-md-0">
          <InformationCircleIcon
            className="flex-shrink-0 me-2"
            style={{ height: 24, width: 24 }}
          />
          <p className="mb-0">{t('contact_person_info_text')}</p>
        </div>
      </div>
    </>
  );
}
