import { useTranslation } from 'react-i18next';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

export default function ConfirmOrderModal({ modalMessage, isSubmitting }) {
  const { t } = useTranslation();

  return (
    <div className="modal fade" id="submitModal" tabIndex="-1">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header border-0">
            <button
              type="button"
              data-bs-dismiss="modal"
              className="btn btn-lg btn-light bg-white border-0 p-0 ms-auto"
            >
              <img
                src="./assets/menuCloseIcon.svg"
                alt="close modal"
                style={{ height: 30, width: 30 }}
              />
            </button>
          </div>

          <div className="modal-body d-flex align-items-center gap-2 pt-5 px-3 heading-5">
            <ExclamationTriangleIcon style={{ minWidth: 80, minHeight: 80 }} />
            {modalMessage}
          </div>

          <div className="modal-footer border-0 p-3">
            <div className="row justify-content-between m-0 p-0 w-100">
              <span
                type="button"
                className="col-6 align-self-center btn-link text-decoration-underline m-0 p-0"
                data-bs-dismiss="modal"
              >
                {t('back_to_order')}
              </span>
              <button
                disabled={isSubmitting}
                type="submit"
                className="col-6 btn btn-primary btn-sm m-0"
                data-bs-dismiss="modal"
              >
                {t('submit_button')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
