import { selector } from 'recoil';

import selectedProducts from './atom';

const getSelectedProducts = selector({
  key: 'getSelectedProducts',
  get: ({ get }) => {
    return get(selectedProducts);
  },
});

export default getSelectedProducts;
