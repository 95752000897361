import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';

import appLanguage from '../../../../recoil/language';
import MenuItem from '../menu-item';
import MenuList from '../menu-list';
import {
  ABOUT_US_URL,
  APPRENTICESHIPS_URL,
  ARBEITEN_BEI_FELLER_URL,
  BILDUNG_URL,
  CONTACT_URL,
  DISCONTINUATIONS_URL,
  DOWNLOAD_URL,
  DUE_COLORE_URL,
  EVENTS_URL,
  FELLER_APPS_URL,
  HELP_CENTER_URL,
  INNOVATION_HUB_HORGEN_URL,
  KNX_URL,
  LOCATIONS_URL,
  NEWSLETTER_URL,
  NEWS_URL,
  OUR_STORY_URL,
  PARTNERS_URL,
  PRESS_AND_MEDIA_URL,
  PRODUKTION_URL,
  REFERENCE_URL,
  SALES_AID_URL,
  SCHALTER_URL,
  SUSTAINABILITY_URL,
  THEMEN_URL,
  TUTORIALS_URL,
  VACANCIES_BEI_FELLER_URL,
  WISER_BY_FELLER_URL,
  WORKPLACE_BEI_FELLER_URL,
} from '../../../../constants/footer';

export default function AccordionMenu() {
  const [language] = useRecoilState(appLanguage);
  const { t } = useTranslation();
  return (
    <div
      className="accordion accordion-flush d-sm-none"
      id="footerMenuAccordion"
    >
      <div className="accordion-item">
        <h2 className="accordion-header">
          <button
            className="accordion-button collapsed heading-5 fw-bolder px-2"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#companyGroup"
          >
            {t('company')}
          </button>
        </h2>
        <div
          id="companyGroup"
          className="accordion-collapse collapse"
          data-bs-parent="#footerMenuAccordion"
        >
          <div className="accordion-body px-2">
            <MenuList>
              <MenuItem name={t('about_us')} url={ABOUT_US_URL[language]} />
              <MenuItem name={t('locations')} url={LOCATIONS_URL[language]} />
              <MenuItem name={t('our_story')} url={OUR_STORY_URL[language]} />
              <MenuItem
                name={t('innovation_hub_horgen')}
                url={INNOVATION_HUB_HORGEN_URL[language]}
              />
              <MenuItem
                name={t('press_and_media')}
                url={PRESS_AND_MEDIA_URL[language]}
              />
              <MenuItem
                name={t('sustainability')}
                url={SUSTAINABILITY_URL[language]}
              />
              <MenuItem name={t('produktion')} url={PRODUKTION_URL[language]} />
              <MenuItem name={t('partners')} url={PARTNERS_URL[language]} />
            </MenuList>
          </div>
        </div>
      </div>

      <div className="accordion-item">
        <h2 className="accordion-header">
          <button
            className="accordion-button collapsed heading-5 fw-bolder px-2"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#quickLinksGroup"
          >
            {t('karriere')}
          </button>
        </h2>
        <div
          id="quickLinksGroup"
          className="accordion-collapse collapse"
          data-bs-parent="#footerMenuAccordion"
        >
          <div className="accordion-body px-2">
            <MenuList>
              <MenuItem
                name={t('workAtFeller')}
                url={ARBEITEN_BEI_FELLER_URL[language]}
              />

              <MenuItem
                name={t('apprenticeships')}
                url={APPRENTICESHIPS_URL[language]}
              />
              <MenuItem
                name={t('vacancies')}
                url={VACANCIES_BEI_FELLER_URL[language]}
              />
              <MenuItem
                name={t('workplace')}
                url={WORKPLACE_BEI_FELLER_URL[language]}
              />
            </MenuList>
          </div>
        </div>
      </div>

      <div className="accordion-item">
        <h2 className="accordion-header">
          <button
            className="accordion-button collapsed heading-5 fw-bolder px-2"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#serviceGroup"
          >
            {t('service')}
          </button>
        </h2>
        <div
          id="serviceGroup"
          className="accordion-collapse collapse"
          data-bs-parent="#footerMenuAccordion"
        >
          <div className="accordion-body px-2">
            <MenuList>
              <MenuItem name={t('contact')} url={CONTACT_URL[language]} />
              <MenuItem
                name={t('help_center')}
                url={HELP_CENTER_URL[language]}
              />

              <MenuItem
                name={t('fellerApps')}
                url={FELLER_APPS_URL[language]}
              />
              <MenuItem name={t('tutorials')} url={TUTORIALS_URL[language]} />
              <MenuItem name={t('salesAids')} url={SALES_AID_URL[language]} />
              <MenuItem
                name={t('discontinuations')}
                url={DISCONTINUATIONS_URL[language]}
              />
              <MenuItem name={t('news')} url={NEWS_URL[language]} />
              <MenuItem name={t('events')} url={EVENTS_URL[language]} />
              <MenuItem name={t('newsletter')} url={NEWSLETTER_URL[language]} />
            </MenuList>
          </div>
        </div>
      </div>

      <div className="accordion-item">
        <h2 className="accordion-header">
          <button
            className="accordion-button collapsed heading-5 fw-bolder px-2"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#locationsGroup"
          >
            {t('frequentlyVisited')}
          </button>
        </h2>
        <div
          id="locationsGroup"
          className="accordion-collapse collapse"
          data-bs-parent="#footerMenuAccordion"
        >
          <div className="accordion-body px-2">
            <MenuList>
              <MenuItem name={t('themen')} url={THEMEN_URL[language]} />
              <MenuItem name={t('reference')} url={REFERENCE_URL[language]} />
              <MenuItem
                name={t('wiser_by_feller')}
                url={WISER_BY_FELLER_URL[language]}
              />
              <MenuItem name={t('bildung')} url={BILDUNG_URL[language]} />
              <MenuItem name={t('schalter')} url={SCHALTER_URL[language]} />
              <MenuItem name={t('dueColore')} url={DUE_COLORE_URL[language]} />
              <MenuItem name={t('knx')} url={KNX_URL[language]} />
              <MenuItem name={t('download')} url={DOWNLOAD_URL[language]} />
            </MenuList>
          </div>
        </div>
      </div>
    </div>
  );
}
