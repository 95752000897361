import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LangugeDetector from 'i18next-browser-languagedetector';

import TRANSLATION_DE from './de/translation.json';
import TRANSLATION_FR from './fr/translation.json';
import TRANSLATION_IT from './it/translation.json';
import languages from '../constants/languages';

if (!Object.values(languages).includes(localStorage.getItem('i18nextLng'))) {
  localStorage.setItem('i18nextLng', 'de');
}

i18n
  .use(initReactI18next)
  .use(LangugeDetector)
  .init({
    fallbackLng: 'de',
    resources: {
      de: { translation: TRANSLATION_DE },
      fr: { translation: TRANSLATION_FR },
      it: { translation: TRANSLATION_IT },
    },
  });

export default i18n;
