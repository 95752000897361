export default function SocialMedia({ url, icon, name, padding = 0 }) {
  return (
    <a href={url} target="_blank" rel="noreferrer">
      <img
        src={icon}
        alt={`${name} page logo`}
        style={{ height: 24, width: 24, padding }}
      />
    </a>
  );
}
