import { useMemo, useState } from 'react';
import userCompanySchema from '../validation/userCompanySchema';
import { useAuth0 } from '@auth0/auth0-react';
export default function useUserData() {
  const [isCheckboxDisabled, setIsCheckboxDisabled] = useState(false);
  const { user } = useAuth0();

  const userMetadata = user ? user['https://id.feller.ch/user_metadata'] : null;

  const userData = useMemo(() => {
    try {
      const validatedUserMetadata = userCompanySchema.validateSync(
        userMetadata
      );

      return {
        street:
          validatedUserMetadata.company_details.headquarter.address.street,
        city: validatedUserMetadata.company_details.headquarter.address.city,
        zip: validatedUserMetadata.company_details.headquarter.address.zip,
      };
    } catch (err) {
      setIsCheckboxDisabled(true);

      return {};
    }
  }, [userMetadata]);

  return { userData, isCheckboxDisabled };
}
