import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';

import appLanguage from '../../../../recoil/language';
import MenuItem from '../menu-item';
import MenuList from '../menu-list';
import {
  CODE_OF_CONDUCT_URL,
  CONDITIONS_OF_PURCHASE_URL,
  DATA_PROTECTION_URL,
  DSGVO_URL,
  GENERAL_SALE_CONDITIONS_URL,
  IMPRINT_URL,
} from '../../../../constants/footer';

export default function CommonLinks() {
  const [language] = useRecoilState(appLanguage);
  const { t } = useTranslation();

  return (
    <div className="row py-5 border-top">
      <div className="col-12 col-md-6">
        <MenuList>
          <MenuItem
            name={t('data_protection')}
            url={DATA_PROTECTION_URL[language]}
          />
          <MenuItem name={t('dsgvo')} url={DSGVO_URL[language]} />
          <MenuItem
            name={t('conditions_of_purchase')}
            url={CONDITIONS_OF_PURCHASE_URL[language]}
          />
          <MenuItem
            name={t('general_sale_conditions')}
            url={GENERAL_SALE_CONDITIONS_URL[language]}
          />
        </MenuList>
      </div>

      <div className="col-12 col-md-6">
        <MenuList>
          <MenuItem
            name={t('code_of_conduct')}
            url={CODE_OF_CONDUCT_URL[language]}
          />
          <MenuItem name={t('imprint')} url={IMPRINT_URL[language]} />
        </MenuList>
      </div>
    </div>
  );
}
