import { atom } from 'recoil';
import data from '../../csv/products.json';
// const withLabelData = {
//   items: [
//     {
//       name: {
//         de: 'Druckschalter, Unbeleuchtet mit Beschriftungsmuster',
//         fr: 'Interrupteur à poussoir, Non lumineux avec motif de lettrage',
//         it: 'Interruttore, Non illuminato con motivo scritta',
//       },
//       id: '62dbccfb-4ac2-4a6c-b7dc-db9e16679c93a',
//       feller_number: '7563.FMI.61',
//       e_nr: '226311000',
//       ean: '7611386839387',
//       design: {
//         color: 'weiss',
//       },
//       category: 'EDIZIOdue colore',
//       image_url:
//         'https://online-katalog.feller.ch/pict/FEG/FEG_7563.FMI.61.PNG',
//       image_name: 'FPR_7563.FMI.61.png',
//       image_label_url:
//         'https://s3.eu-central-1.amazonaws.com/samples-preprod.feller.ch/assets/images/FPR_7563.FMI.61.png',
//     },
//     {
//       id: '9a20f5b9-b281-4e4d-8770-cc926c7682faa',
//       feller_number: '7563.FMI.65',
//       e_nr: '226311030',
//       ean: '7611386817514',
//       design: {
//         color: 'hellgrau',
//       },
//       category: 'EDIZIOdue colore',
//       image_url:
//         'https://online-katalog.feller.ch/pict/FEG/FEG_7563.FMI.65.PNG',
//       image_name: 'FPR_7563.FMI.65.png',
//       image_label_url:
//         'https://s3.eu-central-1.amazonaws.com/samples-preprod.feller.ch/assets/images/FPR_7563.FMI.65.png',
//       name: {
//         de: 'Druckschalter, Unbeleuchtet mit Beschriftungsmuster',
//         fr: 'Interrupteur à poussoir, Non lumineux avec motif de lettrage',
//         it: 'Interruttore, Non illuminato con motivo scritta',
//       },
//     },
//     {
//       id: 'ec8e28c2-0601-4a72-934b-d826a7a920b1a',
//       feller_number: '7563.FMI.67',
//       e_nr: '226311040',
//       ean: '7611386817521',
//       design: {
//         color: 'dunkelgrau',
//       },
//       category: 'EDIZIOdue colore',
//       image_url:
//         'https://online-katalog.feller.ch/pict/FEG/FEG_7563.FMI.67.PNG',
//       image_name: 'FPR_7563.FMI.67.png',
//       image_label_url:
//         'https://s3.eu-central-1.amazonaws.com/samples-preprod.feller.ch/assets/images/FPR_7563.FMI.67.png',
//       name: {
//         de: 'Druckschalter, Unbeleuchtet mit Beschriftungsmuster',
//         fr: 'Interrupteur à poussoir, Non lumineux avec motif de lettrage',
//         it: 'Interruttore, Non illuminato con motivo scritta',
//       },
//     },
//     {
//       id: 'ecdd9789-e70f-4a24-af25-0ac17c529582a',
//       feller_number: '7563.FMI.60',
//       e_nr: '226311950',
//       ean: '7611386817507',
//       design: {
//         color: 'schwarz',
//       },
//       category: 'EDIZIOdue colore',
//       image_url:
//         'https://online-katalog.feller.ch/pict/FEG/FEG_7563.FMI.60.PNG',
//       image_name: 'FPR_7563.FMI.60.png',

//       image_label_url:
//         'https://s3.eu-central-1.amazonaws.com/samples-preprod.feller.ch/assets/images/FPR_7563.FMI.60.png',
//       name: {
//         de: 'Druckschalter, Unbeleuchtet mit Beschriftungsmuster',
//         fr: 'Interrupteur à poussoir, Non lumineux avec motif de lettrage',
//         it: 'Interruttore, Non illuminato con motivo scritta',
//       },
//     },
//     {
//       id: '0aa68643-2ec7-4ea3-a146-0d0432f39ceda',
//       feller_number: '7563.FMI.35',
//       e_nr: '226311010',
//       ean: '7611386658094',
//       design: {
//         color: 'crema',
//       },
//       category: 'EDIZIOdue colore',
//       image_url:
//         'https://online-katalog.feller.ch/pict/FEG/FEG_7563.FMI.35.PNG',
//       image_name: 'FPR_7563.FMI.35.png',
//       image_label_url:
//         'https://s3.eu-central-1.amazonaws.com/samples-preprod.feller.ch/assets/images/FPR_7563.FMI.35.png',
//       name: {
//         de: 'Druckschalter, Unbeleuchtet mit Beschriftungsmuster',
//         fr: 'Interrupteur à poussoir, Non lumineux avec motif de lettrage',
//         it: 'Interruttore, Non illuminato con motivo scritta',
//       },
//     },
//     {
//       id: '83f21ec4-783d-4aef-a34a-f86205d31638a',
//       feller_number: '7563.FMI.57',
//       e_nr: '226311890',
//       ean: '7611386598383',
//       design: {
//         color: 'coffee',
//       },
//       category: 'EDIZIOdue colore',
//       image_url:
//         'https://online-katalog.feller.ch/pict/FEG/FEG_7563.FMI.57.PNG',
//       image_name: 'FPR_7563.FMI.57.png',
//       image_label_url:
//         'https://s3.eu-central-1.amazonaws.com/samples-preprod.feller.ch/assets/images/FPR_7563.FMI.57.png',
//       name: {
//         de: 'Druckschalter, Unbeleuchtet mit Beschriftungsmuster',
//         fr: 'Interrupteur à poussoir, Non lumineux avec motif de lettrage',
//         it: 'Interruttore, Non illuminato con motivo scritta',
//       },
//     },
//   ],
// };

// const withLabelSection = {
//   name: 'with_label_section',
//   key: 'mit Beschriftungsmuster',
//   products: [withLabelData],
// };

let newData = data.map(item => {
  if (item.products && item.products.length >= 26) {
    const updatedProducts = [
      ...item.products.slice(0, 27),
      // withLabelData,
      ...item.products.slice(27),
    ];

    return { ...item, products: updatedProducts };
  }

  return item;
});

// newData = [...newData, withLabelSection];

const products = atom({
  key: 'products',
  default: newData,
});

export default products;
