import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { ALERT_DANGER } from '../../../../../../constants/alert';
import useMessageAlert from '../../../../../../hooks/useMessageAlert';

export default function GenericProduct() {
  const { t } = useTranslation();
  const { setAlert } = useMessageAlert();

  const { isAuthenticated } = useAuth0();

  return (
    <div>
      <label
        role="button"
        className={`card-link btn btn-primary btn-sm d-inline-flex justify-content-center align-items-start gap-1 `}
        onClick={() => {
          if (!isAuthenticated)
            return setAlert(
              t('not_authenticated_message'),
              ALERT_DANGER,
              'top-0'
            );

          if (isAuthenticated) {
            window.location.href = `mailto:${process.env.REACT_APP_ADMIN_EMAIL}`;
          }
        }}
      >
        <input
          name="select-button"
          className="form-check-input position-absolute btn opacity-0"
          type="checkbox"
          disabled={!isAuthenticated}
        />
        <small>{t('please_contact_us')}</small>
      </label>
    </div>
  );
}
