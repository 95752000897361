import { Controller, useFormContext } from 'react-hook-form';

export default function Checkbox({
  checkId,
  required,
  label,
  disabled,
  label2,
}) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <div className="form-check col-12">
      <Controller
        control={control}
        name={checkId}
        rules={{
          required: {
            value: required,
            message: required,
          },
        }}
        render={({ field: { onChange, value } }) => (
          <>
            <input
              id={checkId}
              className={`${
                errors[checkId]?.message ? 'border-danger' : ''
              } form-check-input cursor-pointer`}
              type="checkbox"
              checked={value}
              onChange={onChange}
              disabled={disabled}
            />
            <label
              className="form-check-label cursor-pointer"
              htmlFor={checkId}
            >
              {value && label2 ? label2 : label}
            </label>
          </>
        )}
      />
      {errors[checkId]?.message && (
        <p className="text-danger">{errors[checkId]?.message}</p>
      )}
    </div>
  );
}
