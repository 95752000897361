const handleProductColorChange = (
  products,
  setIsLoading,
  setCurrentColorProduct
) => {
  return id => {
    setIsLoading(true);
    const newProduct = products.find(product => product.id === id);

    if (!newProduct) {
      return;
    }

    const newImage = new Image();

    newImage.src = `/assets/images/${newProduct?.image_name}`;
    window[newProduct.image_name] = `/assets/images/${newProduct?.image_name}`;

    setTimeout(() => {
      setCurrentColorProduct(newProduct);
      setIsLoading(false);
    }, 500);
  };
};

export default handleProductColorChange;
