import { Controller, useFormContext } from 'react-hook-form';
import { OTHER_REASON_MAX_LENGTH } from '../../../../../constants/form';
import { t } from 'i18next';

export default function OtherReason({ otherReasonText }) {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <>
      <p className="mt-4">{otherReasonText}</p>
      <Controller
        control={control}
        name="userOrderReason"
        rules={{
          required: {
            value: true,
            message: t('error_empty_field'),
          },
          maxLength: {
            value: OTHER_REASON_MAX_LENGTH,
            message: t('error_max_length'),
          },
        }}
        render={({ field }) => (
          <textarea
            className={`${
              errors.userOrderReason ? 'border-danger' : ''
            } form-control mb-3`}
            name="userOrderReason"
            rows={6}
            {...field}
          />
        )}
      />
      {errors.userOrderReason && (
        <p className="text-danger">{errors.userOrderReason?.message}</p>
      )}
    </>
  );
}
