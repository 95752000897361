import { useRef, useState, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import {
  MagnifyingGlassIcon,
  ArrowPathIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';

import searchQuery from '../../../../recoil/search/atom';
import useDebounce from '../../../../hooks/useDebounce';

export default function SearchBox({ label, isInDrawer = false }) {
  const [debouncedInputValue, setDebouncedInputValue] = useRecoilState(
    searchQuery
  );
  const inputRef = useRef(null);
  const [isTyping, setIsTyping] = useState(false);
  const [searchInput, setSearchInput] = useState(debouncedInputValue);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);
  const debounce = useDebounce(searchInput, setIsTyping, isMobile);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    setDebouncedInputValue(debounce);
  }, [debounce, setDebouncedInputValue]);

  useEffect(() => {
    setSearchInput(debouncedInputValue);
  }, [debouncedInputValue]);

  useEffect(() => {
    if (!isTyping && isMobile) {
      inputRef.current && inputRef.current.blur();
    }
  }, [isTyping, isMobile]);

  return (
    <div
      className={`${isInDrawer ? 'search-box-drawer' : 'search-box'} ${
        searchInput ? 'outline' : ''
      }`}
    >
      <input
        type="text"
        name="search"
        value={searchInput}
        ref={inputRef}
        onChange={event => setSearchInput(event.target.value)}
        className="search-input"
        placeholder={label}
        autoComplete="off"
      />

      <button
        className="text-primary search-btn d-flex flex-column align-items-center gap-1 border-0 p-0"
        type="button"
        onMouseEnter={() => inputRef.current.focus()}
      >
        {isTyping && (
          <ArrowPathIcon
            strokeWidth="2"
            className="loading"
            style={{ height: 24, width: 24 }}
          />
        )}

        {!isTyping && !searchInput && (
          <MagnifyingGlassIcon
            strokeWidth="2"
            style={{ height: 24, width: 24 }}
          />
        )}

        {!isTyping && searchInput && (
          <XMarkIcon
            strokeWidth="2"
            style={{ height: 24, width: 24 }}
            onClick={() => setSearchInput('')}
          />
        )}

        <span className="search-label">{label}</span>
      </button>
    </div>
  );
}
