import { Controller, useFormContext } from 'react-hook-form';

export default function ReasonRadio({ label, reasonDE, reasonFR, reasonIT }) {
  const { control } = useFormContext();
  return (
    <div className="form-check col-12 pb-3">
      <Controller
        name="reasonForOrder"
        control={control}
        rules={{ required: { value: true } }}
        render={({ field: { value, onChange } }) => (
          <>
            <input
              type="radio"
              id={label}
              className="form-check-input cursor-pointer"
              value={{ de: reasonDE, fr: reasonFR, it: reasonIT }}
              checked={value.de === reasonDE}
              onChange={() =>
                onChange({
                  de: reasonDE,
                  fr: reasonFR,
                  it: reasonIT,
                })
              }
            />
            <label className="form-check-label cursor-pointer" htmlFor={label}>
              {label}
            </label>
          </>
        )}
      />
    </div>
  );
}
