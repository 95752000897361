/* eslint-disable react-hooks/exhaustive-deps */
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import isProfessionalUser from '../utils/isProfessionalUser';

export default function useRedirectEndUser() {
  const { user, isLoading } = useAuth0();
  const userMetadata = user ? user['https://id.feller.ch/user_metadata'] : null;
  const navigate = useNavigate();

  useEffect(() => {
    if (user && !isProfessionalUser(userMetadata?.user_type) && !isLoading) {
      navigate('/unauthorized', { replace: true });
    }
  }, [userMetadata?.user_type]);
}
