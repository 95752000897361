import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

export const useUserUpdateListener = () => {
  const { loginWithRedirect, logout } = useAuth0();

  useEffect(() => {
    const handleMessage = async event => {
      switch (event?.data?.type) {
        case 'FELLER_USER_UPDATE':
          await loginWithRedirect({
            redirect_uri: window.location.origin,
            prompt: 'none',
          });
          break;
        case 'FELLER_USER_DELETE':
        case 'FELLER_USER_LOGOUT':
          logout();
          break;
        default:
          break;
      }
    };

    window.addEventListener('message', handleMessage, false);
    return () => {
      window.removeEventListener('message', handleMessage, false);
    };
  }, [loginWithRedirect, logout]);
};
