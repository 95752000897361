import { useTranslation } from 'react-i18next';

export default function CategorySelect({ name, src, onClick, idx }) {
  const { t } = useTranslation();

  return (
    <div className="col-12 col-md-6 col-lg-3 my-2 py-2 px-1" onClick={onClick}>
      <div
        role="button"
        className="d-flex justify-content-start align-items-center pe-auto"
      >
        <img
          className="me-2"
          style={{ height: 100, width: 100 }}
          src={src}
          alt={`${name} navigation`}
          fetchpriority="high"
        />
        <p className="m-0 btn-link text-decoration-underline">
          {idx === 4 ? t('with_label_section') : name}
        </p>
      </div>
    </div>
  );
}
