import { useRecoilState, useRecoilValue } from 'recoil';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';

import useMessageAlert from '../../hooks/useMessageAlert';
import selectedProducts from '../../recoil/selected-products';
import { getProductById } from '../../recoil/products';
import {
  MAX_SELECTED_PRODUCTS_LENGTH,
  GENERIC_PRODUCT,
} from '../../constants/products';
import { ALERT_DANGER, ALERT_WARNING } from '../../constants/alert';

function useProducts(id, setCurrentProductFromCategories) {
  const [selected, setSelected] = useRecoilState(selectedProducts);
  const { isAuthenticated } = useAuth0();
  const currentProductFromCategories = useRecoilValue(getProductById(id));
  const { setAlert } = useMessageAlert();
  const { t } = useTranslation();

  const currentProduct = currentProductFromCategories || GENERIC_PRODUCT;

  function setSelectedProducts(event) {
    if (!isAuthenticated)
      return setAlert(t('not_authenticated_message'), ALERT_DANGER, 'top-0');

    if (
      selected?.length === MAX_SELECTED_PRODUCTS_LENGTH &&
      event.target.checked
    )
      return setAlert(t('cart_full_message'), ALERT_WARNING, 'top-115');

    if (!event.target.checked) return removeSelected(id);

    setSelected([...selected, currentProduct]);
  }

  function isChecked(id) {
    return selected.some(product => product?.id === id);
  }

  function removeSelected(id) {
    const filtered = selected.filter(product => product?.id !== id);
    setSelected(filtered);
  }

  return {
    setSelectedProducts,
    isChecked,
    removeSelected,
  };
}

export default useProducts;
