import useRedirectEndUser from '../../hooks/useRedirectEndUser';
import Content from '../../components/content';
import MessageAlert from '../../components//message-alert';

export default function Home() {
  useRedirectEndUser();

  return (
    <>
      <MessageAlert />
      <Content />
    </>
  );
}
