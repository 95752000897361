const handleProductLanguage = name => {
  const language = localStorage.getItem('i18nextLng');
  return (
    (language === 'fr' && name?.fr) ||
    (language === 'it' && name?.it) ||
    name?.de
  );
};

export default handleProductLanguage;
